AFRAME.registerComponent("ui-dismiss", {
    schema: {
        uiPanelEl: {type: "selector"},
        buttonGeomEl: {type: "selector"},
        hitboxEl: {type: "selector"},
    },

    init: function () {
        this.clickSoundEl = document.getElementById("ui-click-sound");

        this.data.hitboxEl.setAttribute("visible", false);
        this.createAnimations();

        this.data.buttonGeomEl.setAttribute("material", "color", "#ccc");

        this.data.hitboxEl.addEventListener("raycaster-intersected", () => {
            this.data.buttonGeomEl.emit("cursorIn");
        });
        this.data.hitboxEl.addEventListener("raycaster-intersected-cleared", () => {
            this.data.buttonGeomEl.emit("cursorOut");
        });

        this.data.hitboxEl.addEventListener("click", () => {
            this.clickSoundEl.components.sound.playSound();
            disableUiHitboxes(this.data.uiPanelEl);
            this.data.uiPanelEl.emit("fadeOut");
        });

        this.data.uiPanelEl.addEventListener("animationcomplete__fadeout", () => {
            this.data.uiPanelEl.setAttribute("visible", false);
        });
    },

    createAnimations: function () {
        this.data.buttonGeomEl.setAttribute("animation__cursorIn", {
            startEvents: "cursorIn",
            property: "components.material.material.color",
            type: "color",
            to: "#3F9CFF",
            dur: 200,
        });

        this.data.buttonGeomEl.setAttribute("animation__cursorOut", {
            startEvents: "cursorOut",
            property: "components.material.material.color",
            type: "color",
            to: "#ccc",
            dur: 200,
        });
    },
});
